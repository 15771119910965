import { appEventsMessaging } from "./hooks/pubSub";
import { rmsApi } from "../state/axios";

export const fetchData = async (url, options) => {
  let resultPromise = new Promise(async (resolve, reject) => {
    let pendingResultWaitSub: any = undefined;
    const onResultReady = async (data) => {
      if (data.result_id) {
        let pendingResultUrl = `${url}&pending_result_id=${data.result_id}`;
        try {
          let res = await rmsApi(pendingResultUrl, options);
          if (res.data.result_meta.result_type == "result") {
            if (pendingResultWaitSub) {
              pendingResultWaitSub.unsubscribe();
            }
            resolve(res.data);
          }
          if (res.data.result_meta.result_type == "errorred") {
            if (pendingResultWaitSub) {
              pendingResultWaitSub.unsubscribe();
            }
            reject(res.data);
          }
        } catch (e) {
          if (pendingResultWaitSub) {
            pendingResultWaitSub.unsubscribe();
          }
          reject(e);
        }
      }
    };
    try {
      let res = await rmsApi(url, options);
      if (res.data.result_meta.result_type == "result") {
        if (pendingResultWaitSub) {
          pendingResultWaitSub.unsubscribe();
        }
        resolve(res.data);
      }
      if (res.data.result_meta.result_type == "pending") {
        let pendingResultId = res.data.result_meta.result_id;
        pendingResultWaitSub = appEventsMessaging(
          `pending_result_ready_${pendingResultId}`,
          { onSubscribe: onResultReady, once: true }
        );
        pendingResultWaitSub.subscribe();
      }
    } catch (e) {
      if (pendingResultWaitSub) {
        pendingResultWaitSub.unsubscribe();
      }
      reject(e);
    }
  });
  return await resultPromise;
};
