import styled from "styled-components";
import LoadingAnimation from "../../../../components/loadingAnimation";

const LoadingUI = () => {
  return (
    <Wrapper>
      <LoadingAnimation />
      <p>Loading...</p>
    </Wrapper>
  );
};

export default LoadingUI;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 16px;
  gap: 10px;
`;
