import { Modal } from "react-bootstrap";
import CloseButton from "../reportDialog/closeButton";
import { useExtAConfigs } from "../../hooks/useExtAConfigs";
import styled from "styled-components";
import { useRef } from "react";

const SaveCurrentViewDialog = ({ show = false }) => {
  const { saveCurrentViewDialogHandler, viewControls, views } =
    useExtAConfigs();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    viewControls.saveCurrentView({ name: inputRef.current?.value });
  };

  return (
    <Modal show={show} dialogClassName="ext-a-save-current-view">
      <Modal.Header>
        <Modal.Title>Save Current View</Modal.Title>
        <CloseButton onClick={saveCurrentViewDialogHandler.hideDialog} />
      </Modal.Header>
      <Modal.Body>
        <FormWrapper onSubmit={handleSubmit}>
          <div className="content">
            <input
              type="text"
              ref={inputRef}
              name="view-name-field"
              placeholder="What do you want to call this view?"
              required
            />
          </div>
          <div className="footer">
            <div className="action-buttons">
              <button
                disabled={views.viewSaveState.isLoading}
                className="btn btn-outline action action-cancel"
                onClick={saveCurrentViewDialogHandler.hideDialog}
              >
                Cancel
              </button>
              <button
                disabled={views.viewSaveState.isLoading}
                className="btn btn-secondary action action-save"
              >
                Save
              </button>
            </div>
          </div>
        </FormWrapper>
      </Modal.Body>
    </Modal>
  );
};

export default SaveCurrentViewDialog;

const FormWrapper = styled.form`
  .content {
    height: 10rem;
    input[name="view-name-field"] {
      color: ${({ theme }) => theme.text};
      background: ${({ theme }) => theme.modal.background};
      padding: 10px;
      font-size: 18px;
      font-weight: 400;
      width: 100%;
      height: 64px;
      border-radius: 8px;
      border: 1px solid ${({ theme }) => theme.border_color};
      &::placeholder {
        line-height: 23.4px;
        text-align: left;
      }
    }
  }
  .footer {
    .action-buttons {
      position: absolute;
      bottom: 30px;
      right: 25px;
      display: flex;
      justify-content: end;
      .action {
        width: 124px;
        height: 45px;
        border-radius: 4px;
        border: 1px solid transparent;
        margin: 0px 5px 0px 5px;
        color: #fff;
        border: 1px solid ${({ theme }) => theme.border_color};
        &.action-cancel {
          background: #607D8B;
        }
        &.action-save {
          background: #0D8A6A;
        }
      }
    }
  }
`;
