import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import ExtAContext from "../../contexts/extAContext";
import { useParams } from "react-router-dom";
import { configureUrlWithParams } from "../../../../helpers/utils";
import { RootState } from "../../../../state/store";
import { useSelector } from "react-redux";
import { GridPreDestroyedEvent, GridState, IServerSideGetRowsParams, StateUpdatedEvent } from "ag-grid-charts-enterprise";
import { fetchData } from "../../../../helpers/offthreadFetcher";
import ReportDialog from "../reportDialog";
import SavedViewsDialog from "../savedViewsDialog";
import useExtAViewConfigs from "../../hooks/useExtAViewConfigs";
import { PivotViewComponent } from "@syncfusion/ej2-react-pivotview";
import SaveCurrentViewDialog from "../saveCurrViewDialog";
import CalculatedFieldDg from "../calculatedFieldDg";

interface Props {
  children: ReactNode;
}

const ExtAProvider = ({ children }: Props) => {
  const [reportConfigs, setReportConfigs] = useState({
    title: "",
    widgetUrl: "",
    configs: null,
  });
  const routeMatch = useParams();
  const [loadState, setLoadState] = useState({
    isLoading: false,
    isError: false,
  });
  const [showReport, setShowReport] = useState(false);
  const [showSavedViewsDg, setShowSavedViewsDg] = useState(false);
  const [showSaveCurrentViewDg, setShowSaveCurrentViewDg] = useState(false);
  const [showCalculatedFieldDg, setShowCalculatedFieldDg] = useState(false);

  const [initialState, setInitialState] = useState<GridState>();
  const [currentState, setCurrentState] = useState<GridState>();

  const pivotTableInstance = useRef<PivotViewComponent | null>;
  const pivotTableRef = (refProps: any) => {
    pivotTableInstance.current = refProps;
  };
  const getPivotTableInstance = () => {
    return pivotTableInstance.current;
  };

  const views = useExtAViewConfigs({
    dashboard: routeMatch.dashboard,
    dashboardWidget: routeMatch.widget,
    widgetUrl: reportConfigs.widgetUrl,
    onViewSaveSuccess: () => {
      views.fetchViews();
      setShowSaveCurrentViewDg(false);
    },
    onViewUpdateSuccess: () => {
      views.fetchViews();
      setShowSavedViewsDg(false);
    },
    onViewDeleteSuccess: () => {
      views.fetchViews();
    },
  })

  const { company, filters } = useSelector((state: RootState) => state);
  const timezoneOffset = company?.company_details?.time_zone_offset;

  useEffect(() => {
    if (reportConfigs.widgetUrl) {
      setShowReport(true);
      fetchReportConfigurations();
    } else {
      setShowReport(false);
    }
  }, [reportConfigs.widgetUrl]);

  const ssrmUrl = useMemo(() => {
    const dashboard = routeMatch.dashboard;
    const widget = routeMatch.widget;
    let baseUrl = `/api/v1/dar/${dashboard}/${widget}/${reportConfigs.widgetUrl}/extended`;
    let schemaUrl = `${baseUrl}/ssrm-schema`;
    if (dashboard && widget && reportConfigs.widgetUrl && filters) {
      baseUrl = configureUrlWithParams(baseUrl, {
        ...filters,
        additionalFilters: [],
        shouldUseEpocForTimeRange: true,
        timezoneOffset,
      });
      schemaUrl = configureUrlWithParams(schemaUrl, {
        ...filters,
        additionalFilters: [],
        shouldUseEpocForTimeRange: true,
        timezoneOffset,
      });
    }
    return {
      /** URL for fetching SSRM row data */
      data: baseUrl,
      /** URL for getting initial data grid configurations */
      schema: schemaUrl,
    };
  }, [routeMatch, reportConfigs.widgetUrl, filters]);

  /** SSRM instance for fetching row data */
  const serverSideDataSource = useMemo(
    () => ({
      getRows: (params: IServerSideGetRowsParams) => {
        const { success, fail } = params;
        // Fetch paginated row data
        fetchData(ssrmUrl.data, {
          method: "POST",
          data: { body: params.request },
        })
          .then((res: any) => {
            let rowData = res.extended_data.data ?? [];
            if (
              JSON.stringify(res.extended_data.config) !==
              JSON.stringify(reportConfigs.configs?.config)
            ) {
              setReportConfigs((d) => ({
                ...d,
                configs: {
                  ...d.configs,
                  config: { ...res.extended_data.config },
                },
              }));
            }
            success({
              rowData,
              rowCount: rowData.length > 0 ? rowData[0].total_records : 0,
            });
          })
          .catch((err: any) => {
            fail();
          });
      },
      destroy: () => {},
    }),
    [ssrmUrl.data]
  );

  /** function to fetch initial SSRM Configuration to load the report */
  const fetchReportConfigurations = useCallback(async () => {
    if (ssrmUrl.schema) {
      setLoadState({ isLoading: true, isError: false });
      try {
        let data: any = await fetchData(ssrmUrl.schema, { method: "GET" });
        setReportConfigs((d) => ({
          ...d,
          title: data?.extended_data?.config?.report_title ?? "",
          configs: data?.extended_data,
        }));
        setLoadState({ isLoading: false, isError: false });
      } catch (e) {
        console.log(e);
        setLoadState({ isLoading: false, isError: true });
      }
    }
  }, [ssrmUrl.schema]);

  const dialogHandler = {
    showReport: ({ widgetUrl = "" }) => {
      setReportConfigs((d) => ({ ...d, widgetUrl: widgetUrl }));
    },
    hideReport: () => {
      setReportConfigs((d) => ({ widgetUrl: "", configs: null, title: "" }));
      setInitialState(undefined);
      setCurrentState(undefined);
    },
  };

  const calcFieldDialogHandler = {
    showDialog: () => {
      setShowCalculatedFieldDg(true);
    },
    hideDialog: () => {
      setShowCalculatedFieldDg(false);
    }
  }

  const savedVwsDialogHandler = {
    showDialog: () => {
      views.fetchViews();
      setShowSavedViewsDg(true);
    },
    hideDialog: () => {
      setShowSavedViewsDg(false);
    },
  };

  const saveCurrentViewDialogHandler = {
    showDialog: () => {
      setShowSaveCurrentViewDg(true);
    },
    hideDialog: () => {
      setShowSaveCurrentViewDg(false);
    },
  };

  const viewControls = {
    saveCurrentView: ({ name = "" }) => {
      let snapshot = getPivotTableInstance().api.getColumnState();
      // let snapshot = currentState; // TODO: Full save state
      views.saveNewView({ name, snapshot });
    },
    overwriteView: (id: any) => {
      let viewDetails = views.savedViews.find(
        (view: any) => view.id === id
      ) ?? { name: "" };
      views.updateView({
        id,
        name: viewDetails?.name,
        snapshot: getPivotTableInstance().api.getColumnState(),
        // snapshot: currentState, // TODO: Full save state
      });
    },
    deleteView: (id: any) => {
      views.deleteView({ id });
    },
    loadSelectedView: (snapshot: any) => {
      // setInitialState(snapshot); // TODO: Full save state
      getPivotTableInstance()?.api?.applyColumnState({ state: snapshot });
    },
  };

  const onGridPreDestroyed = useCallback(
    (params: GridPreDestroyedEvent) => {
      const { state } = params;
      console.log("Grid state on destroy (can be persisted)", state);
      setInitialState(state);
    },
    [],
  );

  const onStateUpdated = useCallback(
    (params: StateUpdatedEvent) => {
      console.log("State updated", params.state);
      setCurrentState(params.state);
    },
    [],
  );

  const printState = useCallback(() => {
    console.log("Grid state", currentState);
  }, [currentState]);

  // const gridState = {
  //   initialState,
  //   onGridPreDestroyed,
  //   onStateUpdated,
  //   printState,
  // };

  return (
    <ExtAContext.Provider
      value={{
        fetchReportConfigurations,
        serverSideDataSource,
        dialogHandler,
        loadState,
        reportConfigs,
        showReport,
        savedVwsDialogHandler,
        views,
        viewControls,
        pivotTableRef,
        getPivotTableInstance,
        calcFieldDialogHandler,
        saveCurrentViewDialogHandler,
        // gridState, // TODO: Full save state
      }}
    >
      <ReportDialog show={showReport} />
      <CalculatedFieldDg show={showCalculatedFieldDg} />
      <SavedViewsDialog show={showSavedViewsDg} />
      <SaveCurrentViewDialog show={showSaveCurrentViewDg} />
      {children}
    </ExtAContext.Provider>
  );
};

export default ExtAProvider;
