import { Modal } from "react-bootstrap";
import { useExtAConfigs } from "../../hooks/useExtAConfigs";
import CloseButton from "./closeButton";
import LoadingView from "../../../extendedAnalytics/components/loadingView";
import FetchErrorView from "../../../extendedAnalytics/components/fetchErrorView";
import PivotTable from "../pivotTable";
import styled from "styled-components";

const ReportDialog = ({ show = false }) => {
  const {
    reportConfigs,
    dialogHandler,
    loadState,
    savedVwsDialogHandler,
    saveCurrentViewDialogHandler,
    calcFieldDialogHandler,
  } = useExtAConfigs();
  return (
    <Modal
      show={show}
      size="lg"
      dialogClassName="custom curved-wide-report extended-a-report"
    >
      <Modal.Header>
        <Modal.Title>{reportConfigs.title}</Modal.Title>
        {reportConfigs.configs ? (
          <ToolbarWrapper>
            {/* <button
              className="btn btn-toolbar-item"
              style={{ background: "#0d8a6a", color: "#ffffff" }}
              onClick={calcFieldDialogHandler.showDialog}
            >
              Calculated Field
            </button> */}
            <button
              className="btn btn-toolbar-item"
              style={{
                background: "#2b7bc5",
                color: "#ffffff",
                border: "transparent",
              }}
              onClick={savedVwsDialogHandler.showDialog}
            >
              Saved Views
            </button>
            <button
              className="btn btn-toolbar-item"
              style={{
                background: "#565656",
                color: "#ffffff",
                border: "transparent",
              }}
              onClick={saveCurrentViewDialogHandler.showDialog}
            >
              Save Current View
            </button>
          </ToolbarWrapper>
        ) : null}
        <CloseButton onClick={dialogHandler.hideReport} />
      </Modal.Header>
      <Modal.Body>
        {loadState.isLoading ? (
          <LoadingView />
        ) : loadState.isError ? (
          <FetchErrorView errorType={"fetch"} refreshData={() => {}} />
        ) : reportConfigs.configs ? (
          <PivotTable />
        ) : null}
      </Modal.Body>
    </Modal>
  );
};

export default ReportDialog;

const ToolbarWrapper = styled.div`
  .btn-toolbar-item {
    background: #ededed;
    color: #000000;
    border: 1px solid #e1e1e1;
    margin: 0px 5px 0px;
  }
`;
