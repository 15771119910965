import { AgGridReact } from "ag-grid-react";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { RootState } from "../../../../state/store";
import { ColDef } from "ag-grid-charts-enterprise";
import { useExtAConfigs } from "../../hooks/useExtAConfigs";
import { ColTypeDef, themeQuartz } from "ag-grid-community";

const PivotTable = () => {
  const theme = useSelector((state: RootState) => state.theme.mode);

  const { pivotTableRef, serverSideDataSource, reportConfigs, gridState, getPivotTableInstance } =
    useExtAConfigs();
  const gridConfig = reportConfigs.configs;

  // Year range
  const dateFilters = useSelector((state: RootState) => state.filters.dates);
  const [startYear, endYear] = dateFilters.map((date) => parseInt(date.split("-")[0]));

  const columnDefs = useMemo(() => {
    if (gridConfig) {
      return gridConfig?.config.fields.map((field) => {
        let hide = false;
        if(field.field == "discounts") {
          hide =true
        }
        return {
          ...field,
          hide: true,
        };
      });
    }
    return [];
  }, [gridConfig]);

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      minWidth: 130,
      enableValue: true,
      enableRowGroup: true,
      enablePivot: true,
      resizable: true,
      lockPinned: true,
      suppressHeaderMenuButton: false,
      suppressHeaderContextMenu: false,
    };
  }, []);

  function getFilterYearLength(startYear: number, endYear: number) {
    let diff = endYear - startYear;
    let years: number[] = [startYear];
    if (diff > 0) {
      for (let i = startYear; i <= endYear; i++) {
        i += 1;
        years.push(i.toString());
      }
    }
    return years;
  }

  const colTypes = useMemo<{
    [key: string]: ColTypeDef;
  }>(() => {
    return {
      number: { filter: "agNumberColumnFilter" },
      string: { enableValue: false, filter: "agTextColumnFilter" },
      dateYear: {
        enableValue: false,
        filter: "agSetColumnFilter",
        filterParams: {
          values: getFilterYearLength(startYear, endYear),
        },
      },
      dateMonth: {
        enableValue: false,
        sortable: true,
        filter: "agSetColumnFilter",
      },
      dateDay: {
        enableValue: false,
        filter: "agSetColumnFilter",
      },
    };
  }, [startYear, endYear]);

  const reportTheme = useMemo(() => {
    return theme === "dark"
      ? themeQuartz.withParams({
          accentColor: "#0D8A6A",
          backgroundColor: "#212121",
          browserColorScheme: "dark",
          foregroundColor: "#FFFFFF",
        })
      : themeQuartz.withParams({
          accentColor: "#0D8A6A",
          browserColorScheme: "light",
          headerFontSize: 14,
        });
  }, [theme]);

  const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  return (
    <Wrapper style={containerStyle}>
      <div style={gridStyle}>
        <AgGridReact
          ref={pivotTableRef}
          theme={reportTheme}
          columnDefs={columnDefs}
          columnTypes={colTypes}
          defaultColDef={defaultColDef}
          gridOptions={{
            allowDragFromColumnsToolPanel: false,
          }}
          enableCharts={true}
          cellSelection
          rowGroupPanelShow="always"
          groupDisplayType="groupRows"
          sideBar={{
            toolPanels: [
              {
                id: "columns",
                labelDefault: "Columns",
                labelKey: "columns",
                iconKey: "columns",
                toolPanel: "agColumnsToolPanel",
                toolPanelParams: {
                  suppressPivotMode: true, // Set to true to disable pivot mode toggle
                  suppressPivots: false, // Set true to disable column grouping
                  suppressColumnFilter: true, // Set true to hide the filter input
                  suppressColumnSelectAll: true, // Set true to hide the "Select All" checkbox
                },
              },
              {
                id: "filters",
                labelDefault: "Filters",
                labelKey: "filters",
                iconKey: "filter",
                toolPanel: "agFiltersToolPanel",
              },
            ],
          }}
          grandTotalRow={"bottom"}
          pivotMode
          serverSideSortAllLevels
          rowModelType="serverSide"
          serverSideDatasource={serverSideDataSource}
          // initialState={gridState.initialState} // TODO: implement grid state
          // onGridPreDestroyed={gridState.onGridPreDestroyed}
          // onStateUpdated={gridState.onStateUpdated}
        />
      </div>
    </Wrapper>
  );
};

export default PivotTable;

const Wrapper = styled.div`
`;
