import { useState } from "react";
import * as viewOperations from "../helpers/viewOperations";
import Swal from "sweetalert2";

interface Params {
  dashboard: string | undefined;
  dashboardWidget: string | undefined;
  widgetUrl: string;
  onViewSave?: () => void;
  onViewSaveSuccess?: () => void;
  onViewSaveError?: () => void;
  onViewUpdate?: () => void;
  onViewUpdateSuccess?: () => void;
  onViewUpdateError?: () => void;
  onViewDelete?: () => void;
  onViewDeleteSuccess?: () => void;
  onViewDeleteError?: () => void;
}

const useExtAViewConfigs = ({
  dashboard = "",
  dashboardWidget = "",
  widgetUrl = "",
  onViewSave = () => {},
  onViewSaveSuccess = () => {},
  onViewSaveError = () => {},
  onViewUpdate = () => {},
  onViewUpdateSuccess = () => {},
  onViewUpdateError = () => {},
  onViewDelete = () => {},
  onViewDeleteSuccess = () => {},
  onViewDeleteError = () => {},
}: Params) => {
  const [savedViews, setSavedviews] = useState([]);
  const [viewFetchState, setViewFetchState] = useState({
    isLoading: false,
    isError: false,
  })
  const [viewSaveState, setViewSaveState] = useState({
    isLoading: false,
    isError: false,
  });
  const [viewUpdateState, setViewUpdateState] = useState({
    isLoading: false,
    isError: false,
  });
  const [viewDeleteState, setViewDeleteState] = useState({
    isLoading: false,
    isError: false,
  });

  const fetchViews = async () => {
    try {
      setViewFetchState({
        isLoading: true,
        isError: false,
      })
      let result: any = await viewOperations.fetchViews({
        dashboard,
        widget: dashboardWidget,
        url: widgetUrl,
      });
      setViewFetchState({
        isLoading: false,
        isError: false,
      });
      setSavedviews(result.data);
      return Promise.resolve(result);
    } catch (e) {
      setViewFetchState({
        isLoading: false,
        isError: true,
      })
      return Promise.reject(e);
    }
  };

  const saveNewView = async ({ name, snapshot }) => {
    setViewSaveState({
      isLoading: true,
      isError: false,
    });
    onViewSave();
    try {
      let result = await viewOperations.saveNewView({
        name,
        payload: snapshot,
        dashboard,
        widget: dashboardWidget,
        url: widgetUrl,
      });
      if (result.status == 200) {
        setViewSaveState({
          isLoading: false,
          isError: false,
        });
        onViewSaveSuccess();
      }
    } catch (error) {
      setViewSaveState({
        isLoading: false,
        isError: true,
      });
      onViewSaveError();
    }
  };

  const updateView = async ({ id, name, snapshot }) => {
    const feedback = await Swal.fire({
      icon: "question",
      title: "Do you want to overwrite this view with current one?",
      text: "This action is irreversable",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (feedback.isConfirmed) {
      setViewUpdateState({
        isLoading: true,
        isError: false,
      });
      onViewUpdate();
      try {
        let result = await viewOperations.updateView(id, {
          name,
          payload: snapshot,
          dashboard,
          widget: dashboardWidget,
          url: widgetUrl,
        });
        if (result.status == 200) {
          setViewUpdateState({
            isLoading: false,
            isError: false,
          });
          onViewUpdateSuccess();
        }
      } catch (error) {
        setViewUpdateState({
          isLoading: false,
          isError: true,
        });
        onViewUpdateError();
      }
    }
  };

  const deleteView = async ({ id }) => {
    const feedback = await Swal.fire({
      icon: "question",
      title: "Are you sure you want to delete this view?",
      text: "This action is irreversable",
      showCancelButton: true,
      allowOutsideClick: false,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    });
    if (feedback.isConfirmed) {
      setViewDeleteState({
        isLoading: true,
        isError: false,
      });
      onViewDelete();
      try {
        let result = await viewOperations.deleteView(id, {
          dashboard,
          widget: dashboardWidget,
          url: widgetUrl,
        });
        if (result.status == 200) {
          setViewDeleteState({
            isLoading: false,
            isError: false,
          });
          onViewDeleteSuccess();
        }
      } catch (error) {
        setViewDeleteState({
          isLoading: false,
          isError: true,
        });
        onViewDeleteError();
      }
    }
  };

  return {
    savedViews,
    fetchViews,
    saveNewView,
    updateView,
    deleteView,
    viewSaveState,
    viewUpdateState,
    viewDeleteState,
    viewFetchState,
  };
};

export default useExtAViewConfigs;
