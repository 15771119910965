import { RiFolderInfoLine } from "react-icons/ri";
import styled from "styled-components";

const EmptyUI = () => {
  return (
    <Wrapper>
      <RiFolderInfoLine style={{ fontSize: "70px" }}  />
      <p>No saved views</p>
    </Wrapper>
  );
};

export default EmptyUI;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-items: center;
  font-size: 16px;
  gap: 10px;
`;
