import { Fragment, lazy, Suspense, useEffect, useMemo, useRef, useState } from "react";
import { MapRef, NavigationControl } from "react-map-gl";
import { useSelector } from "react-redux";
import {
  CUSTOM_COLOR_SCALE,
  CUSTOM_COLOR_SCALE_INVERT,
} from "../../../helpers/constants";
import { RootState } from "../../../state/store";
import { MapConfiguration } from "../../../types/widgets";
import { ColorScaleLegend } from "./colorScaleLegend";
import ComparisonBarChart from "../../comparison/barChart";
import BarLineComboChart from "../barComboWidget/barComboChart";
import { MapLayerPopup } from "./hoverPopup";
import { MapLayers } from "./mapLayers";
import { TextMarkerControl } from "./textMarkerControl";
import ContentLoader from "react-content-loader";
import { TiWarning } from "react-icons/ti";
import {
  calculateBoundingBoxCoords,
  calculateCenter,
  convertToGeoJsonData,
  createColorGradientConfig,
  getMaxScaleValue,
  getMinScaleValue,
} from "./utils";
const BaseMap = lazy(() => import("../../baseMap"));


interface IBubbleMapProps {
  width: number;
  height: number;
  data: MapConfiguration;
  title: string;
  type: string;
  invertColorScale: boolean;
}

const BubbleMap = (props: IBubbleMapProps) => {
  const theme = useSelector((state: RootState) => state.theme);
  const mapRef = useRef<MapRef>(null);
  const [geoJsonData, setGeoJsonData] = useState({});
  const [numericCountSum, setNumericCountSum] = useState(0);
  const [layerHoverData, setLayerHoverData] = useState<{
    point: { x: number; y: number };
    coordinates: { lat: number; lng: number };
    content: any;
  } | null>(null);
  const [maxAmountValue, setMaxAmountValue] = useState(0);
  const [minAmountValue, setMinAmountValue] = useState(0);
  const [maxCountValue, setMaxCountValue] = useState(0);
  const [minScaleValue, setMinScaleValue] = useState(0);
  const [maxScaleValue, setMaxScaleValue] = useState(0);
  const [showMarkerLabels, setShowMarkerLabels] = useState(true);
  const [colorScaleTitle, setColorScaleTitle] = useState("");
  const [mapLoaded, setMapLoaded] = useState(false);
  const [gradientConfig, setGradientConfig] = useState<any>([]);
  const [mapMissingCoords, setMapMissingCoords] = useState(false);
  const [mapData, setMapData] = useState<MapConfiguration>([]);
  const [circleSizeLegendTitle, setCircleSizeLegendTitle] = useState("");

  useEffect(() => {
    const mapInstance = mapRef.current;
    if (mapInstance && geoJsonData && mapLoaded) {
      zoomToBounds(geoJsonData.features.map((loc) => loc.geometry.coordinates));
      mapInstance.on("mousemove", "circleMarker", handleLayerOnMouseOver);
      mapInstance.on("mouseleave", "circleMarker", handleLayerOnMouseOut);
    }
    return () => {
      if (mapInstance) {
        mapInstance.off("mouseover", () => {});
        mapInstance.off("mouseleave", () => {});
      }
    };
  }, [mapLoaded, geoJsonData, mapRef.current]);

  useEffect(() => {
    if (props.data) {
      setMapData(props.data);
      if (props.data.length > 0) {
        validateLocationCoordinates(props.data, () =>
          setGeoJsonData(
            convertToGeoJsonData(calculateSumOfNumericCountData(props.data))
          )
        );
      }
    }
  }, [props.data]);

  useEffect(() => {
    if ("features" in geoJsonData) {
      zoomToBounds(geoJsonData.features.map((d) => d.geometry.coordinates));
    }
  }, [geoJsonData]);

  const validateLocationCoordinates = (data, callback) => {
    let coordinates = data.map((d) => d.location_coordinates);
    let missingCoords = coordinates.filter((c) => c[0] === 0 && c[1] === 0);
    if (missingCoords.length > 0) {
      setMapMissingCoords(true);
    } else {
      callback();
    }
  };

  const zoomToBounds = (coordsList) => {
    try {
      if (mapRef.current) {
        let { bounds, center } = calculateBoundingBoxCoords(coordsList);
        mapRef.current.fitBounds(bounds, {
          padding: 50,
          center,
        });
      }
    } catch (error) {
      mapRef.current?.flyTo({
        center: [INITIAL_LOCATION.lon, INITIAL_LOCATION.lat],
        zoom: 10,
      });
    }
  };

  const handleResetZoom = () => {
    if ("features" in geoJsonData) {
      zoomToBounds(geoJsonData.features.map((d) => d.geometry.coordinates));
    }
  };

  const calculateSumOfNumericCountData = (apiData: MapConfiguration) => {
    setNumericCountSum(
      apiData.reduce(
        (total, loc) => total + loc.location_data.numeric_amount_value,
        0
      )
    );
    let maxAmount = Math.max(
      ...apiData.map((d) => d.location_data.numeric_amount_value)
    );
    let minAmount = Math.min(
      ...apiData.map((d) => d.location_data.numeric_amount_value)
    );
    setMaxScaleValue(getMaxScaleValue(maxAmount));
    setMinScaleValue(getMinScaleValue(minAmount));
    setMaxAmountValue(maxAmount);
    setMinAmountValue(minAmount);
    setMaxCountValue(
      Math.max(...apiData.map((d) => d.location_data.numeric_count_value))
    );
    setColorScaleTitle(apiData[0].location_data.numeric_amount_name);
    setCircleSizeLegendTitle(apiData[0].location_data.numeric_count_name);
    let colorScale = props.invertColorScale
      ? CUSTOM_COLOR_SCALE_INVERT
      : CUSTOM_COLOR_SCALE;
    setGradientConfig(
      createColorGradientConfig(
        colorScale,
        getMinScaleValue(minAmount),
        getMaxScaleValue(maxAmount)
      )
    );
    return apiData;
  };

  const handleLayerOnMouseOver = (event) => {
    const {
      features,
      point,
      lngLat: { lat, lng },
    } = event;
    setLayerHoverData({
      point,
      content: [features[0]],
      coordinates: { lat, lng },
    });
  };

  const handleLayerOnMouseOut = (event) => {
    setLayerHoverData(null);
  };

  const onMapLoad = () => {
    setMapLoaded(true);
  };

  const barLineComboAPI = (data) => {
    let payload = {
      config: {
        line: {
          name: data[0].location_data.numeric_amount_name,
          data_point: "",
          selector: "",
          value_formatter: data[0].location_data.numeric_amount_postfix,
        },
        bar: {
          name: data[0].location_data.numeric_count_name,
          data_point: "",
          selector: "",
          value_formatter: data[0].location_data.numeric_count_postfix,
        },
      },
      data: {
        series: data.map((d) => {
          return {
            category: d.location_name,
            line_value: d.location_data.numeric_amount_value,
            bar_value: d.location_data.numeric_count_value,
            line_id: "",
            bar_id: "",
          };
        }),
      },
    };
    return payload;
  };

  const mapCenterCoordinates = useMemo(() => {
    let coordinatesArray = props.data.map((location) => {
      return location.location_coordinates;
    });
    return calculateCenter(coordinatesArray);
  }, [props.data])
  
  return (
    <Fragment>
      {mapData.length > 0 ? (
        <Fragment>
          {props.type === "MAP_BUBBLE_LG" && !mapMissingCoords ? (
            <Suspense
              fallback={
                <>
                  <ContentLoader
                    width={props.width}
                    height={props.height}
                    speed={3}
                    backgroundColor={
                      theme.mode === "light" ? "#f3f3f3" : "#1f1f1f"
                    }
                    foregroundColor={
                      theme.mode === "light" ? "#ecebeb" : "#171717"
                    }
                  >
                    <rect
                      x="0"
                      y="40"
                      rx="2"
                      ry="2"
                      width={props.width - 10}
                      height={props.height - 40}
                    />
                  </ContentLoader>
                </>
              }
            >
              <BaseMap
                initialViewState={{
                  latitude: mapCenterCoordinates.latitudeCenter,
                  longitude: mapCenterCoordinates.logitudeCenter,
                  zoom: 15,
                }}
                onMapLoad={onMapLoad}
                dimensions={{ width: props.width, height: props.height }}
                theme={theme.mode}
                mapRef={mapRef}
              >
                <NavigationControl position="bottom-left" />
                <TextMarkerControl
                  checkboxValue={showMarkerLabels}
                  setCheckboxValue={setShowMarkerLabels}
                  resetZoom={handleResetZoom}
                />
                <ColorScaleLegend
                  scaleTitle={colorScaleTitle}
                  minValue={minScaleValue}
                  maxValue={maxScaleValue}
                  invertScale={props.invertColorScale}
                />
                {geoJsonData && (
                  <MapLayers
                    gradientConfig={gradientConfig}
                    maxCountValue={maxCountValue}
                    showMarkerLabels={showMarkerLabels}
                    geoJsonData={geoJsonData}
                  />
                )}
                {layerHoverData?.coordinates && (
                  <MapLayerPopup layerHoverData={layerHoverData} />
                )}
              </BaseMap>
            </Suspense>
          ) : (
            <div>
              <>
                <div>
                  <u>Locations</u>
                  <div
                    style={{
                      fontSize: "16px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#FD9D44",
                      margin: "15px 0",
                    }}
                  >
                    <span>
                      <span>
                        <TiWarning />
                      </span>{" "}
                      To enable map view, please configure geolocation data for
                      the selected locations.
                    </span>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <BarLineComboChart //update css here
                    data={barLineComboAPI(props.data)}
                    width={props.width}
                    height={props.height - 50}
                  />
                </div>
              </>
            </div>
          )}
        </Fragment>
      ) : (
        <div
          style={{
            display: "flex",
            width: props.width,
            height: props.height,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Error
        </div>
      )}
    </Fragment>
  );
};

export default BubbleMap;
